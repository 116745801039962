<script>
import * as stringHelper from '@/helpers/string'

export default {
  components: {

  },
  props: {
    user: Object
  },
  data() {
    return {
      partner: null,
      env: process.env.NODE_ENV,
      info: {
        username: '',
        apiKey: '',
        useSeamless: 0,
        prefix: '',
        partner: null
      },
      domain: {
        design: {
          logo: '',
          logoSmall: ''
        }
      },
      seamlessCallbackUrl: {
        authenticate: '',
        balance: '',
        bet: '',
        result: '',
        cancel: '',
        partner: null
      },
      detailCallbackUrl: {
        url: '',
        partner: null
      },
      transferCallbackUrl: {
        balance: '',
        partner: null
      },
      title: this.$t('API 설정')
    };
  },
  methods: {
    async saveInfo() {
      if (this.env !== 'development') {
        if (this.$store.getters['auth/user'].type !== 'admin') return
      }

      const loader = this.$loading.show({
        container: this.$refs.infoWrapper
      })
      try {
        if (this.user) {
          this.info.partner = this.user.id
        }
        const result = await this.$API.user.saveInfo(this.info)
        if (result.code !== 0) {
          return alert(result.msg)
        }
      } catch (e) {
        this.$log.error(e)
        alert('INTERNAL_ERROR')
      } finally {
        loader.hide()
      }
    },
    async saveTransfer() {
      const loader = this.$loading.show({
        container: this.$refs.transferWrapper
      })
      try {
        if (this.user) {
          this.transferCallbackUrl.partner = this.user.id
        }

        this.transferCallbackUrl.balance = this.transferCallbackUrl.balance.trim()
        if (this.transferCallbackUrl.balance) {
          if (stringHelper.validateUrl(this.transferCallbackUrl.balance) === false) {
            return alert('머니이동의 잔액확인 URL을 확인하여 주세요.')
          }
        }

        const result = await this.$API.user.saveTransferCallbackUrl(this.transferCallbackUrl)
        if (result.code !== 0) {
          return alert(result.msg)
        }
      } catch (e) {
        this.$log.error(e)
        alert('INTERNAL_ERROR')
      } finally {
        loader.hide()
      }
    },
    async saveDetail() {
      const loader = this.$loading.show({
        container: this.$refs.detailWrapper
      })
      try {
        if (this.user) {
          this.detailCallbackUrl.partner = this.user.id
        }

        this.detailCallbackUrl.url = this.detailCallbackUrl.url.trim()
        if (this.detailCallbackUrl.url) {
          if (stringHelper.validateUrl(this.detailCallbackUrl.url) === false) {
            return alert('게임 상세 정보 URL을 확인하여 주세요.')
          }
        }

        const result = await this.$API.user.saveDetailCallbackUrl(this.detailCallbackUrl)
        if (result.code !== 0) {
          return alert(result.msg)
        }
      } catch (e) {
        this.$log.error(e)
        alert('INTERNAL_ERROR')
      } finally {
        loader.hide()
      }
    },
    async saveSeamless() {
      const loader = this.$loading.show({
        container: this.$refs.seamlessWrapper
      })
      try {
        if (this.user) {
          this.seamlessCallbackUrl.partner = this.user.id
        }

        this.seamlessCallbackUrl.authenticate= this.seamlessCallbackUrl.authenticate.trim()
        if(this.seamlessCallbackUrl.authenticate) {
          if (stringHelper.validateUrl(this.seamlessCallbackUrl.authenticate) === false) {
            return alert('심리스의 인증 URL을 확인하여 주세요.')
          }
        }

        this.seamlessCallbackUrl.balance= this.seamlessCallbackUrl.balance.trim()
        if (this.seamlessCallbackUrl.balance) {
          if (stringHelper.validateUrl(this.seamlessCallbackUrl.balance) === false) {
            return alert('심리스의 잔액확인 URL을 확인하여 주세요.')
          }
        }

        this.seamlessCallbackUrl.bet= this.seamlessCallbackUrl.bet.trim()
        if (this.seamlessCallbackUrl.bet) {
          if (stringHelper.validateUrl(this.seamlessCallbackUrl.bet) === false) {
            return alert('심리스의 배팅 URL을 확인하여 주세요.')
          }
        }

        this.seamlessCallbackUrl.result= this.seamlessCallbackUrl.result.trim()
        if (this.seamlessCallbackUrl.result) {
          if (stringHelper.validateUrl(this.seamlessCallbackUrl.result) === false) {
            return alert('심리스의 결과 URL을 확인하여 주세요.')
          }
        }

        this.seamlessCallbackUrl.cancel= this.seamlessCallbackUrl.cancel.trim()
        if (this.seamlessCallbackUrl.cancel) {
          if (stringHelper.validateUrl(this.seamlessCallbackUrl.cancel) === false) {
            return alert('심리스의 취소 URL을 확인하여 주세요.')
          }
        }

        const result = await this.$API.user.saveSeamlessCallbackUrl(this.seamlessCallbackUrl)
        if (result.code !== 0) {
          return alert(result.msg)
        }
      } catch (e) {
        this.$log.error(e)
        alert('INTERNAL_ERROR')
      } finally {
        loader.hide()
      }
    },
    async saveDomain() {
      const loader = this.$loading.show({
        container: this.$refs.branchWrapper
      })
      try {
        if (this.user) {
          this.info.partner = this.user.id
        }

        if (!this.domain.design.logo) {
          return alert('로고 이미지 파일을 선택하여 주세요.')
        }

        if (!this.domain.design.logoSmall) {
          return alert('모바일용 로고 이미지 파일을 선택하여 주세요.')
        }

        let formData = new FormData()
        formData.append('logo', this.domain.design.logo)
        formData.append('logoSmall', this.domain.design.logoSmall)

        const result = await this.$API.user.saveDomain(formData)
        if (result.code !== 0) {
          return alert(result.msg)
        }

        document.location.reload()

      } catch (e) {
        this.$log.error(e)
        alert('INTERNAL_ERROR')
      } finally {
        loader.hide()
      }
    },
    getDomain: async function() {
      const domain = await this.$API.user.domain()
      if (domain.code !== 0) {
        return alert(domain.msg)
      }
      this.domain = domain.domain
    },
    handleLogoImage(e) {
      if (e.target.files[0]) {
        this.domain.design.logo = e.target.files[0]
      }
      // console.log(this.domain.design.logo)
    },
    handleLogoImageSmall(e) {
      if (e.target.files[0]) {
        this.domain.design.logoSmall = e.target.files[0]
      }
    }
  },
  mounted() {
    this.partner = this.user ? this.user : this.$store.getters['auth/user']
    this.info.username = this.partner.username
    this.info.apiKey = this.partner.apiKey
    this.info.useSeamless = this.partner.useSeamless
    this.info.prefix = this.partner.prefix

    this.detailCallbackUrl.url = this.partner.detailCallbackUrl

    this.transferCallbackUrl.balance = this.partner.transferCallbackUrl ? this.partner.transferCallbackUrl.balance : ''
    this.seamlessCallbackUrl.authenticate = this.partner.seamlessCallbackUrl ? this.partner.seamlessCallbackUrl.authenticate : ''
    this.seamlessCallbackUrl.balance = this.partner.seamlessCallbackUrl ? this.partner.seamlessCallbackUrl.balance : ''
    this.seamlessCallbackUrl.bet = this.partner.seamlessCallbackUrl ? this.partner.seamlessCallbackUrl.bet : ''
    this.seamlessCallbackUrl.result = this.partner.seamlessCallbackUrl ? this.partner.seamlessCallbackUrl.result : ''
    this.seamlessCallbackUrl.cancel = this.partner.seamlessCallbackUrl ? this.partner.seamlessCallbackUrl.cancel : ''
  }
};
</script>

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div ref="branchWrapper" class="mb-3" v-if="$store.getters['auth/user'].useDomain === 1">
                <form enctype="multipart/form-data" @submit.prevent="saveDomain()">
                  <h4>API 디자인 설정</h4>
                  <hr />
                  <div class="row mb-3">
                    <div class="col-md-3">
                      <label class="form-label">로고파일</label>
                    </div>
                    <div class="col-md-9">
                      <input type="file" class="form-control" accept="image/*" @change="handleLogoImage" />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-3">
                      <label class="form-label">로고파일(모바일)</label>
                    </div>
                    <div class="col-md-9">
                      <input type="file" class="form-control" accept="image/*" @change="handleLogoImageSmall" />
                    </div>
                  </div>
                  <!--
                  <div class="row mb-3">
                    <div class="col-md-3">
                      <label class="form-label">사이드메뉴 배경색</label>
                    </div>
                    <div class="col-md-9">
                      <input type="text" class="form-control" />
                    </div>
                  </div>
                  //-->
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button type="submit" class="btn btn-primary w-100"> {{ $t('저장') }}</button>
                    </div>
                  </div>

                </form>
                <hr />
              </div>

              <div ref="infoWrapper">
                <form @submit.prevent="saveInfo()">
                  <h4>API 인증 정보</h4>
                  <hr />
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card bg-transparent border border-secondary">
                        <div class="card-body">
                          <p class="mb-0">API를 사용하기 위한 기본 정보입니다.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-3">
                      <label class="form-label">k-username</label>
                    </div>
                    <div class="col-md-9">
                      <input type="text" class="form-control" v-model="info.username" :disabled="$store.getters['auth/user'].type !== 'admin' && this.env !== 'development'" />
                      <p class="form-text">* API 호출 시 Header에 포함되야 합니다.</p>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-3">
                      <label class="form-label">k-secret</label>
                    </div>
                    <div class="col-md-9">
                      <input type="text" class="form-control" v-model="info.apiKey" :disabled="$store.getters['auth/user'].type !== 'admin' && this.env !== 'development'" />
                      <p class="form-text">* API 호출 시 Header에 포함되야 합니다.</p>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-3">
                      <label class="form-label">PREFIX</label>
                    </div>
                    <div class="col-md-9">
                      <input type="text" class="form-control" v-model="info.prefix" :disabled="$store.getters['auth/user'].type !== 'admin' && this.env !== 'development'" />
                      <p class="form-text">* 사이트 회원을 API에 가입 시 '_'를 포함하여 자동으로 붙습니다.</p>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-3">
                      <label class="form-label">{{ $t('지갑 형식') }}</label>
                    </div>
                    <div class="col-md-9">
                      <select class="form-select" v-model="info.useSeamless" :disabled="$store.getters['auth/user'].type !== 'admin' && this.env !== 'development'">
                        <option :value="0">{{ $t('머니이동') }}</option>
                        <option :value="1">{{ $t('심리스') }}</option>
                      </select>
                      <p class="form-text">* API의 형식을 선택할 수 있습니다.</p>
                    </div>
                  </div>

                  <div class="row" v-if="$store.getters['auth/user'].type === 'admin' || this.env === 'development'">
                    <div class="col-md-12 text-center">
                      <button type="submit" class="btn btn-warning w-100">{{ $t('저장') }}</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-6">
              <div ref="detailWrapper">
                <form @submit.prevent="saveDetail()">
                  <h4>게임 Detail Callback URL</h4>
                  <hr />
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card bg-transparent border border-secondary">
                        <div class="card-body">
                          <p class="mb-0">상세 정보가 나중에 오는 경우 이 데이터를 사이트로 전송합니다.(에볼루션,드림게이밍,.)</p>
                          <p class="mb-0 text-warning">* 사용하지 않는 경우 빈칸으로 남겨두세요.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-3">
                      <label class="form-label">상세정보</label>
                    </div>
                    <div class="col-md-9">
                      <input type="text" class="form-control" v-model="detailCallbackUrl.url" placeholder="정확한 URL을 입력하세요." />
                      <p class="form-text">* 실시간 Detail Data를 설정된 주소로 전송합니다.</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button type="submit" class="btn btn-warning w-100"> {{ $t('저장') }}</button>
                    </div>
                  </div>
                </form>
                <hr />
              </div>
              <div ref="transferWrapper" v-if="info.useSeamless === 0">
                <form @submit.prevent="saveTransfer()">
                  <h4>트랜스퍼 Balance Callback URL</h4>
                  <hr />
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card bg-transparent border border-secondary">
                        <div class="card-body">
                          <p class="mb-0">트랜스퍼를 사용하시는 경우 실시간 잔액 정보를 수신할 Callback URL을 입력하세요.</p>
                          <p class="mb-0 text-warning">* 사용하지 않는 경우 빈칸으로 남겨두세요.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-3">
                      <label class="form-label">잔액확인</label>
                    </div>
                    <div class="col-md-9">
                      <input type="text" class="form-control" v-model="transferCallbackUrl.balance" :disabled="info.useSeamless === 1" />
                      <p class="form-text">* 실시간으로 회원의 잔액 정보 변경 내역을 사이트로 전송합니다.</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button type="submit" class="btn btn-warning w-100" :disabled="info.useSeamless === 1"><span class="fa fa-save"></span> {{ $t('저장') }}</button>
                    </div>
                  </div>
                </form>
                <hr />
              </div>

              <div ref="seamlessWrapper" v-if="info.useSeamless === 1">
                <form @submit.prevent="saveSeamless()">
                  <h4>심리스 Callback URL</h4>
                  <hr />
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card bg-transparent border border-secondary">
                        <div class="card-body">
                          <p class="mb-0">심리스를 사용하시는 경우 아래 Callback URL을 입력하세요.</p>
                          <p class="mb-0 text-warning">* 사용하지 않는 경우 빈칸으로 남겨두세요.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-3">
                      <label class="form-label">인증</label>
                    </div>
                    <div class="col-md-9">
                      <input type="text" class="form-control" v-model="seamlessCallbackUrl.authenticate" :disabled="info.useSeamless !== 1" />
                      <p class="form-text">* 사이트에 <span class="text-danger">회원인증을 요청</span>합니다.</p>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-3">
                      <label class="form-label">잔액확인</label>
                    </div>
                    <div class="col-md-9">
                      <input type="text" class="form-control" v-model="seamlessCallbackUrl.balance" :disabled="info.useSeamless !== 1" />
                      <p class="form-text">* 사이트에 <span class="text-danger">회원의 잔액 정보를 요청</span>합니다.</p>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-3">
                      <label class="form-label">배팅</label>
                    </div>
                    <div class="col-md-9">
                      <input type="text" class="form-control" v-model="seamlessCallbackUrl.bet" :disabled="info.useSeamless !== 1" />
                      <p class="form-text">* 사이트에 <span class="text-danger">회원의 배팅 내역</span>을 전송합니다.</p>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-3">
                      <label class="form-label">결과</label>
                    </div>
                    <div class="col-md-9">
                      <input type="text" class="form-control" v-model="seamlessCallbackUrl.result" :disabled="info.useSeamless !== 1" />
                      <p class="form-text">* 사이트에 <span class="text-danger">회원의 배팅 결과 내역</span>을 전송합니다.</p>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-3">
                      <label class="form-label">취소</label>
                    </div>
                    <div class="col-md-9">
                      <input type="text" class="form-control" v-model="seamlessCallbackUrl.cancel" :disabled="info.useSeamless !== 1" />
                      <p class="form-text">* 사이트에 <span class="text-danger">회원의 배팅 취소 내역</span>을 전송합니다.</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <button type="submit" class="btn btn-warning w-100" :disabled="info.useSeamless !== 1"><span class="fa fa-save"></span> {{ $t('저장') }}</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end col -->
  </div>
</template>
