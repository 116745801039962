<script>
import Layout from "../_layouts/main"
import PageHeader from "@/components/page-header"
import Api from '../../components/setting/api'

export default {
  components: {
    Layout,
    PageHeader,
    Api
  },
  data() {
    return {
      title: this.$t('API 설정')
    }
  },
  mounted() {

  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <Api />
  </Layout>
</template>
